import React from 'react';
// import { InscritosModel } from 'types/models/inscritos';
import { RoundedButton } from 'components/NewInputs';
import I18n from 'i18n-js';
import configuration from 'config';
import styles from './community-autoreport-list-row.module.scss';

interface CommunityListRowProps {
  row: any;
  communityID: string;
}

export const CommunityAutoreportListRow = (props: CommunityListRowProps): JSX.Element => {
  const { row } = props;
  const { t } = I18n;

  // Función para exportar la fila como CSV
  return (
    <tr className={styles.mainrow}>
      <td>{row.nombre === row.apellidos ? row.nombre : `${row.nombre} ${row.apellidos}`}</td>
      <td>{row.cups}</td>
      <td>{row.nif}</td>
      <td>{row.comunidad}</td>
      <td>{row.informeOrigenDeDatos}</td>
      <td>{row.tipoConsumo}</td>
      <td>{row.terminoEnergiaP1}</td>
      <td>{row.terminoEnergiaP2}</td>
      <td>{row.terminoEnergiaP3}</td>
      <td>{row.terminoEnergiaP4}</td>
      <td>{row.terminoEnergiaP5}</td>
      <td>{row.terminoEnergiaP6}</td>
      <td style={{ textAlign: 'center' }}>{row.precioCompensacion}</td>
      <td style={{ textAlign: 'center' }}>{row.informeExcedente !== '-' ? `${row.informeExcedente} kwh ` : '-'}</td>
      <td style={{ textAlign: 'center' }}>
        {row.informeAutoconsumo && row.informeAutoconsumo !== '-' ? `${row.informeAutoconsumo} kwh` : '-'}
      </td>
      <td style={{ textAlign: 'center' }}>
        {row.informeConsumoAnual !== '-' ? `${row.informeConsumoAnual} kwh ` : '-'}
      </td>

      <td style={{ textAlign: 'center' }}>
        {row.informePorcentajeDeAutoconsumo && row.informePorcentajeDeAutoconsumo !== '-'
          ? `${row.informePorcentajeDeAutoconsumo}%`
          : '-'}
      </td>

      <td style={{ textAlign: 'center' }}>
        {row.informeAhorro && row.informeAhorro !== '-' ? `${row.informeAhorro.toFixed(2)}€` : '-'}
      </td>
      {row.token && (
        <td>
          <RoundedButton
            onClick={() => {
              window.open(`${configuration.urlInformeAutomatico}${row.token}`, '_blank');
            }}
          >
            {t(`ComunitiesEnrolledListPage.detalles`)}
          </RoundedButton>
        </td>
      )}
    </tr>
  );
};
