/* eslint-disable no-nested-ternary */
import I18n from 'i18n-js';
import React from 'react';
import { AutoInforme } from 'types/models/AutoInforme';
import { CommunityAutoreportListRow } from '../community-autoreport-list-row/community-autoreport-list-row';
import styles from './communities-autoreport-list-table.module.scss';

interface CommunitiesEnrolledListTableProps {
  data: AutoInforme[];
  communityID: string;
}

const TableHeader = ({ t }: { t: (key: string) => string }) => (
  <thead>
    <tr>
      <th>{t('ComunitiesAutoReportListPage.headers.name')}</th>
      <th>{t('ComunitiesAutoReportListPage.headers.CUPS')}</th>
      <th>DNI (NIE)</th>
      <th>{t('ComunitiesAutoReportListPage.headers.nombreComunidad')}</th>
      <th>{t('ComunitiesAutoReportListPage.headers.Origen')}</th>
      <th>{t('ComunitiesAutoReportListPage.headers.Tipo')}</th>
      <th>P1</th>
      <th>P2</th>
      <th>P3</th>
      <th>P4</th>
      <th>P5</th>
      <th>P6</th>
      <th>P.EX</th>
      <th style={{ textAlign: 'center' }}>{t('ComunitiesAutoReportListPage.headers.Excedente')}</th>
      <th style={{ textAlign: 'center' }}>{t('ComunitiesAutoReportListPage.headers.autoconsumo')}</th>
      <th style={{ textAlign: 'center' }}>{t('ComunitiesAutoReportListPage.headers.ConsumoAnual')}</th>
      <th style={{ textAlign: 'center' }}>{t('ComunitiesAutoReportListPage.headers.autoconsumo')}</th>
      <th style={{ textAlign: 'center' }}>{t('ComunitiesAutoReportListPage.headers.Ahorro')}</th>
      <th aria-label="Detail" />
    </tr>
  </thead>
);
const TableBody = ({ data, communityID }: { data: AutoInforme[]; communityID: string }) => (
  <tbody>
    {data.map((row) => (
      <CommunityAutoreportListRow key={row.id} row={row} communityID={communityID} />
    ))}
  </tbody>
);

export const CommunitiesAutoreportListTable = (props: CommunitiesEnrolledListTableProps): JSX.Element => {
  const { data, communityID } = props;
  const { t } = I18n;

  if (data.length === 0) {
    return <p>{t('ComunitiesAutoReportListPage.headers.notResult')}</p>;
  }

  return (
    <table className={styles.table_wrapper}>
      {communityID === undefined ? (
        <p>{t('ComunitiesAutoReportListPage.headers.notResult')}</p>
      ) : (
        <>
          <TableHeader t={t} />
          <TableBody data={data} communityID={communityID} />
        </>
      )}
    </table>
  );
};
