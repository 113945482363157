import { AxiosResponse } from 'axios';
import configuration from 'config';
import { AutoInforme } from 'types/models/AutoInforme';
import apiService from './ApiService';

export class AutoInformeService {
  readonly http = apiService;

  private apiUrl = configuration.apiUrl;

  async getAutoInformeSolicitud(id: string): Promise<string> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/informes-automaticos/solicitud/`;

    const response: AxiosResponse = await this.http.get(url);
    return response.data.datos.estado;
  }

  public async createAutoInformeSolicitud(id: string): Promise<number> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/informes-automaticos/solicitud/`;

    const response: AxiosResponse<{ status: number }> = await this.http.post(url);
    return response.status;
  }

  async deleteAutoInformeSolicitud(id: string): Promise<unknown> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/informes-automaticos/solicitud/`;

    const response: AxiosResponse<number> = await this.http.delete(url);

    return response;
  }

  async getAutoInforme(id: string): Promise<AutoInforme[]> {
    const url = `${this.apiUrl}/ce/comunidades-energeticas/${id}/informes-automaticos/`;

    const response: AxiosResponse = await this.http.get(url);
    const result = new AutoInforme().deserializeArray(response.data.datos);
    return result;
  }
}
const autoInformeService = new AutoInformeService();
export default autoInformeService;
