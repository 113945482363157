import { Serializable } from 'types/interfaces';

export interface AutoInformeAPI {
  id?: string;
  nombre?: string;
  apellidos?: string;
  nif?: string;
  email?: string;
  cups?: string;
  direccionCompleta?: string;
  telefono?: string;
  nifEmpresa?: string;
  razonSocialEmpresa?: string;
  tipoConsumo?: string;
  precioCompensacion?: number;
  terminoEnergiaP1?: number;
  terminoEnergiaP2?: number;
  terminoEnergiaP3?: number;
  terminoEnergiaP4?: number;
  terminoEnergiaP5?: number;
  terminoEnergiaP6?: number;
  nombreComunidad?: string; // Para 'comunidad'
  informeOrigenDeDatos?: string;
  informeExcedente?: string;
  informeConsumoAnual?: string;
  informePorcentajeDeAutoconsumo?: string;
  informeAhorro?: string;
  fechaDeCreacionDeInforme?: string;
  informeAutoconsumo?: string;
  token?: string;
  comunidad?: string;
}

export class AutoInforme implements Serializable<AutoInforme> {
  id: string;

  nombre: string;

  apellidos: string;

  nif: string;

  email: string;

  cups: string;

  direccionCompleta: string;

  telefono: string;

  nifEmpresa: string;

  razonSocialEmpresa: string;

  tipoConsumo: string;

  precioCompensacion: number;

  terminoEnergiaP1: number;

  terminoEnergiaP2: number;

  terminoEnergiaP3: number;

  terminoEnergiaP4: number;

  terminoEnergiaP5: number;

  terminoEnergiaP6: number;

  nombreComunidad: string; // Para 'comunidad'

  informeExcedente: string;

  informeOrigenDeDatos: string;

  informeConsumoAnual: string;

  informePorcentajeDeAutoconsumo: string;

  informeAhorro: string;

  fechaDeCreacionDeInforme: string;

  token: string;

  comunidad: string;

  informeAutoconsumo: string;

  deserialize(input: AutoInformeAPI): AutoInforme {
    if (!input) {
      return this;
    }

    this.id = input.id || '';

    this.nombre = input.nombre || '';

    this.apellidos = input.apellidos || '';

    this.nif = input.nif || '';

    this.email = input.email || '';

    this.cups = input.cups || '';

    this.direccionCompleta = input.direccionCompleta || '';

    this.telefono = input.telefono || '';

    this.nifEmpresa = input.nifEmpresa || '';

    this.razonSocialEmpresa = input.razonSocialEmpresa || '';

    this.tipoConsumo = input.tipoConsumo || '';

    this.precioCompensacion = input.precioCompensacion || 0;

    this.terminoEnergiaP1 = input.terminoEnergiaP1 || 0;

    this.terminoEnergiaP2 = input.terminoEnergiaP2 || 0;

    this.terminoEnergiaP3 = input.terminoEnergiaP3 || 0;

    this.terminoEnergiaP4 = input.terminoEnergiaP4 || 0;

    this.terminoEnergiaP5 = input.terminoEnergiaP5 || 0;

    this.terminoEnergiaP6 = input.terminoEnergiaP6 || 0;

    this.nombreComunidad = input.nombreComunidad || ''; // Para 'comunidad'

    this.informeOrigenDeDatos = input.informeOrigenDeDatos || '-';
    this.informeExcedente = input.informeExcedente || '-';
    this.informeConsumoAnual = input.informeConsumoAnual || '-';

    this.informePorcentajeDeAutoconsumo = input.informePorcentajeDeAutoconsumo || '-';

    this.informeAhorro = input.informeAhorro || '-';

    this.fechaDeCreacionDeInforme = input.fechaDeCreacionDeInforme || '-';
    this.informeAutoconsumo = input.informeAutoconsumo || '-';

    this.token = input.token || '';

    this.comunidad = input.comunidad || '';

    return this;
  }

  serialize(): AutoInformeAPI {
    return {
      id: this.id,

      nombre: this.nombre,

      apellidos: this.apellidos,

      nif: this.nif,

      email: this.email,

      cups: this.cups,

      direccionCompleta: this.direccionCompleta,

      telefono: this.telefono,

      nifEmpresa: this.nifEmpresa,

      razonSocialEmpresa: this.razonSocialEmpresa,

      tipoConsumo: this.tipoConsumo,

      precioCompensacion: this.precioCompensacion,

      terminoEnergiaP1: this.terminoEnergiaP1,

      terminoEnergiaP2: this.terminoEnergiaP2,

      terminoEnergiaP3: this.terminoEnergiaP3,

      terminoEnergiaP4: this.terminoEnergiaP4,

      terminoEnergiaP5: this.terminoEnergiaP5,

      terminoEnergiaP6: this.terminoEnergiaP6,

      nombreComunidad: this.nombreComunidad, // Para 'comunidad'

      informeOrigenDeDatos: this.informeOrigenDeDatos,
      informeExcedente: this.informeExcedente,

      informeConsumoAnual: this.informeConsumoAnual,

      informePorcentajeDeAutoconsumo: this.informePorcentajeDeAutoconsumo,

      informeAhorro: this.informeAhorro,

      fechaDeCreacionDeInforme: this.fechaDeCreacionDeInforme,
      informeAutoconsumo: this.informeAutoconsumo,

      token: this.token,

      comunidad: this.comunidad,
    } as AutoInformeAPI;
  }

  deserializeArray(inputArray: Array<AutoInformeAPI>): Array<AutoInforme> {
    return inputArray.map((input) => new AutoInforme().deserialize(input));
  }

  setAttr<T>(key: string, value: T): void {
    Object.assign(this, { [key]: value });
  }
}
