import React from 'react';
import I18n from 'i18n-js';
import { LoadingButton } from 'components';
import styles from './communities-autoreport-list-filters.module.scss';

interface FilterText {
  handleChange(value: any): void;
  statusSolicitud: string;
  createAutoInformeSolicitud(): void;
  deleteAutoInformeSolicitud(): void;
}

export const CommunitiesAutoreportListFilters = (props: FilterText): JSX.Element => {
  const { t } = I18n;
  const { statusSolicitud, createAutoInformeSolicitud, deleteAutoInformeSolicitud } = props;
  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <p className={styles.title}>{t(`ComunitiesAutoReportListPage.title`)}</p>
      </div>
      <p className={styles.tiempoEjecutar}> {statusSolicitud}</p>
      {statusSolicitud !== 'PENDIENTE' && (
        <div className={styles.btnFinal}>
          <LoadingButton
            className={styles.loadingButtonAddUser}
            type="submit"
            text={I18n.t('ComunitiesAutoReportListPage.actionCreate')}
            onClick={() => {
              if (statusSolicitud === 'ACABADO') {
                deleteAutoInformeSolicitud();
              } else {
                createAutoInformeSolicitud();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
